<app-header></app-header>

<form [formGroup]="requestForm" (ngSubmit)="validate()">
    <div class="container center-h center-v">
        <p><small> Acreditación Domicilio > Solicitud</small> </p><br>
        <mat-card-title>
            <small> Acreditación Domicilio / Solicitud </small>
        </mat-card-title>
        <div class="cardResponse  " *ngIf="!cardResponse">
            <mat-card-content>
                <strong>
                    <p>
                        <center>Si le realizaron la encuesta Sisbén hace menos de un mes, no es necesaria la
                            acreditación de
                            domicilio hasta
                            pasados cuatro (4), meses contados desde la fecha de realización de la visita de la
                            encuesta.</center>
                    </p>
                    <p>
                        <center>Si le realizaron la Encuesta Sisbén hace más de un mes, debe revisar sus datos en la
                            siguiente
                            página web y
                            en caso de que su documento de identificación no se encuentre actualizado o de que la
                            encuesta
                            Sisbén se
                            encuentre en estado "En verificación", debe ir a un punto de atención del Sisbén en un CADE
                            para
                            resolver el
                            problema</center>
                    </p>

                    <a href="https://portal.sisben.gov.co/Paginas/consulta-tu-grupo.html">
                        <center>https://portal.sisben.gov.co/Paginas/consulta-tu-grupo.html</center>
                    </a>
                    <br>
                    <p>
                        <center> Si no le han realizado la Encuesta Sisbén, la puede solicitar en la siguiente página
                            web:
                        </center>
                    </p>
                    <a href="https://sisbensol.sdp.gov.co/">
                        <center>https://sisbensol.sdp.gov.co/</center>
                    </a>
                </strong>
            </mat-card-content>
        </div>
        <br>
        <mat-card-subtitle>
            <strong>Por favor ingrese sus datos basicos para certificar su afiliación</strong>
        </mat-card-subtitle>



        <div class="centrofrom center-h center-v">
            <mat-card-content>

                <div class="subti">
                    <p><strong>Información de la solicitud</strong><br>
                        &nbsp; &nbsp; &nbsp; <small> *Campos Obligatorios</small>
                    </p>
                </div>
                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>Número de PPT</mat-label>
                        <input matInput placeholder="Numero" type="number" maxlength="8" #docNum
                            formControlName="DocNum" [required]="!isRequiredInput" name="DocNum" (keydown)="isAllowedKey($event)">
                        <mat-hint align="end">{{docNum.value.length}} / 8</mat-hint>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>Primer apellido</mat-label>
                        <input matInput placeholder="Primer apellido" required formControlName="Surname" name="Surname" onkeydown="return /[a-z, Ññ]/i.test(event.key)"
                            [required]="!isRequiredInput">
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>Fecha de nacimiento</mat-label>
                        <input matInput [matDatepicker]="dp3" required formControlName="BirthDate" name="BirthDate"
                            [required]="!isRequiredInput" [disabled]="true">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                        <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>


<!--
       <section class="sectionCheck">
                    <mat-label></mat-label>
                    <mat-checkbox [(ngModel)]="checked" color="primary" required formControlName="acceptPolicy" name="acceptPolicy" >Politica de Tratamiento de datos</mat-checkbox>
        </section> -->

            </mat-card-content>

            <div class="card">
            </div>
        </div>




        <mat-card-actions>
            <button mat-raised-button color="primary" type="submit" class="btn">Validar</button>
        </mat-card-actions>


    </div>


</form>

<app-footer [title]="title" [fecha]="fecha"></app-footer>




